<template>
  <div class="card">
    <div class="cover">
      <img
        :src="
          require(`@/views/map/preview/static/temp_${dataSource.mapTemplate}/preview.png`)
        "
        alt="cover"
      />
    </div>
    <div class="content">
      <h5>{{ dataSource.taskName }}</h5>
      <p>
        <!-- 关卡数： -->
        {{ $t("XB_NumberLevels") }}：<strong
          >{{ dataSource.passStages }} / {{ dataSource.stages }}</strong
        >
      </p>
      <p>
        <!-- 资源明细数： -->
        {{ $t("map.resource_details") }}：<strong
          >{{ dataSource.passDetails }} / {{ dataSource.details }}</strong
        >
      </p>
      <p>
        <!-- 持续天数： -->
        {{ $t("LB_Map_DurationDays") }}：<strong
          >{{ dataSource.effective }}
          <template v-if="dataSource.dateType == 1">{{
            $t("Pub_Day")
          }}</template>
          <!-- 天 -->
          <template v-if="dataSource.dateType == 2">{{
            $t("order.month")
          }}</template>
          <!-- 个月 -->
          <template v-if="dataSource.dateType == 3">{{
            $t("CM_Year")
          }}</template>
          <!-- 年 -->
        </strong>
      </p>
      <p>
        <!-- 状态： -->
        {{ $t("CM_Status") }}：<strong>{{
          dataSource.complete == 1 ? $t("CM_Completed") : $t("CM_NotCompleted")
        }}</strong>
        <!-- '已完成' : '未完成' -->
      </p>
      <div class="bottom">
        <router-link
          class="link"
          :to="
            '/map/detail?id=' +
            dataSource.taskId +
            '&temp=' +
            dataSource.mapTemplate
          "
        >
          {{ $t("view_detail") }} <RightOutlined />
          <!-- 查看详情 -->
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "PackageCard",
  props: {
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup() {
    const store = useStore();

    let useEnterprise = computed(
      () => store.getters.platformConfig.useEnterprise
    );

    return {
      useEnterprise,
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  height: 337px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.15);
  background-color: #fff;
  transition: all 0.3s ease;
  position: relative;
  .mixinFlex(space-between);
  .cover {
    .mixinImgWrap(600px; 337px);
    border-radius: 8px 0px 0px 8px;
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      background-color: rgba(255, 255, 255, 0);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 0.3s ease;
    }
  }
  .content {
    width: calc(100% - 600px);
    padding: 20px;
    h5 {
      font-size: 22px;
      .mixinEllipsis(68px, 2);
      margin-bottom: 44px;
    }
    p {
      color: #666;
      font-size: 20px;
      line-height: 30px;
      margin: 5px 0;
      strong {
        color: @color-theme;
        font-weight: normal;
      }
      i {
        font-size: 14px;
        padding-left: 2px;
      }
    }
    .bottom {
      text-align: right;
      margin-top: 12px;
      .link {
        display: inline-block;
        color: #fff;
        font-size: 14px;
        width: 106px;
        height: 40px;
        background-color: @color-theme;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        line-height: 40px;
        text-align: center;
      }
    }
  }
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.25);
    .cover {
      &::after {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
</style>
